var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('hr',{staticClass:"-mx-2"}),_c('div',{staticClass:"flex justify-between py-2.5"},[_c('div',{staticClass:"font-bold text-headerColor w-[40%]",class:_vm.helpPage && 'flex items-center'},[_vm._v(" "+_vm._s(_vm.header.cellA)+" "),(_vm.helpPage)?_c('button',{staticClass:"ml-2 w-[20px]",on:{"click":_vm.handleHelpClick}},[_c('QuestionCircleSolid',{staticClass:"text-brandLightBlue"})],1):_vm._e()]),_c('div',{staticClass:"font-bold text-headerColor w-[15%] text-center"},[_vm._v(" "+_vm._s(_vm.header.cellB)+" ")]),_c('div',{staticClass:"font-bold text-headerColor w-[15%] text-center"},[_vm._v(" "+_vm._s(_vm.header.cellC)+" ")]),_c('div',{staticClass:"font-bold text-headerColor w-[15%] text-center"},[_vm._v(" "+_vm._s(_vm.header.cellD)+" ")]),_c('div',{staticClass:"font-bold text-headerColor w-[15%] text-center"},[_vm._v(" "+_vm._s(_vm.header.cellE)+" ")])]),_c('hr',{staticClass:"-mx-2"}),_c('transition-group',{attrs:{"name":"transition-list","tag":"div"}},_vm._l((_vm.items),function(item,idx){return _c('div',{key:item[_vm.itemKey] + idx,staticClass:"transition-list-item",on:{"click":function($event){return _vm.$emit('itemClicked', item)}}},[_c('div',{staticClass:"-mx-2 transition-colors hover:bg-gray-100"},[_c('div',{staticClass:"flex justify-between items-center py-1.5 px-2",class:_vm.itemHasCursor ? 'cursor-pointer' : null},[_c('div',{class:`${
              item[_vm.body.cellD].toString().length > 4 ? 'w-[30%]' : 'w-[40%]'
            }`},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"text-headerColor truncate mr-1",class:_vm.options && _vm.options.cellARegularFont ? null : 'font-bold'},[_vm._v(" "+_vm._s(item[_vm.body.cellA.title])+" ")]),(item[_vm.body.cellA.hasIcon])?_vm._t("cellAIcon"):_vm._e()],2),_c('div',{staticClass:"truncate text-headerColor"},[_vm._v(" "+_vm._s(item[_vm.body.cellA.subtitle])+" ")])]),_c('div',{staticClass:"text-[22px] text-headerColor w-[15%] text-center"},[_vm._v(" "+_vm._s(item[_vm.body.cellB])+" ")]),(_vm.trafficLightItems.includes(_vm.body.cellC))?_c('div',{staticClass:"text-[22px] text-headerColor w-[15%] text-center"},[_c('TrafficLight',{staticClass:"m-auto",attrs:{"color":item[_vm.body.cellC]}})],1):_c('div',{staticClass:"text-[22px] text-headerColor w-[15%] text-center"},[_vm._v(" "+_vm._s(item[_vm.body.cellC])+" ")]),_c('div',{staticClass:"text-[22px] text-headerColor text-center",class:`${
              item[_vm.body.cellD].toString().length > 4 ? 'w-[30%]' : 'w-[15%]'
            }`},[_vm._v(" "+_vm._s(item[_vm.body.cellD])+" ")]),_c('div',{staticClass:"text-[22px] text-headerColor text-center",class:`${
              item[_vm.body.cellE].toString().length > 4 ? 'w-[30%]' : 'w-[15%]'
            }`},[_vm._v(" "+_vm._s(item[_vm.body.cellE])+" ")])])]),_c('hr',{staticClass:"-mx-2"})])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }