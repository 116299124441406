var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Master',[_c('div',{staticClass:"configurator-help"},[_c('div',{staticClass:"container"},[_c('PageHeading',{attrs:{"title":"SSC to SSC Cross-Sourcing Admin"}}),(!_vm.invalid)?_c('div',[_c('button',{staticClass:"mt-3 font-bold text-brandGreen",on:{"click":function($event){$event.preventDefault();return _vm.handleSetMultiple()}}},[_vm._v(" Set Multiple "),_c('i',{staticClass:"icon-right-open-big ml-2"})]),_c('hr',{staticClass:"-mx-2 mt-5"}),_vm._l((_vm.items),function(item,idx){return _c('div',{key:idx},[(item)?_c('table2',{attrs:{"header":{
              cellA: item.FunctionName,
              cellB: 'Capacity',
            },"body":{
              cellA: {
                title: 'CapName',
                subtitle: 'Contact',
              },
              cellB: 'color',
            },"orderBy":'CapName',"items":item.Capabilities,"itemHasCursor":true,"options":{
              cellARegularFont: false,
              truncate: false,
              lineBreak: true,
              trafficLights: 'color',
            },"itemKey":"CapCode"},on:{"itemClicked":(item) => _vm.showDetails(item)}}):_vm._e(),(item)?_c('hr',{staticClass:"-mx-2 mt-2"}):_vm._e()],1)})],2):_c('div',{staticClass:"flex items-center mt-4 border-t border-brandGray border-solid"},[_c('SvgExclamationTriangle',{staticClass:"text-brandYellow w-[22px] h-[22px] fill-current mt-2"}),_c('div',{staticClass:"flex justify-center items-center ml-4 pt-4"},[_vm._v(" SSC to SSC Cross-Sourcing not found."),_c('br'),_vm._v("Please, check back later. ")])],1),(!_vm.invalid)?_c('button',{staticClass:"mt-6 font-bold text-brandGreen",on:{"click":function($event){$event.preventDefault();return _vm.handleReturnToTop()}}},[_vm._v(" Return to Top "),_c('i',{staticClass:"icon-right-open-big ml-2"})]):_vm._e()],1)]),(_vm.isLoading)?_c('loader'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }