var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Master',[_c('div',{staticClass:"inventory-pooling-details"},[_c('div',{staticClass:"container"},[_c('div',{},[_c('PageHeading',{attrs:{"title":_vm.branchName}}),_c('div',{staticClass:"mt-1 text-strategy mb-2"},[_vm._v(_vm._s(_vm.swagelokBranchName))]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"icon-swg-phone text-brandBlue text-xl mr-2"}),_c('a',{staticClass:"text-brandGreen mb-1",attrs:{"href":`tel:${_vm.partnerSSCData.phone.replace(/\D/g, '')}`}},[_vm._v(_vm._s(_vm.partnerSSCData.phone))])]),_c('div',{staticClass:"flex gap-2"},[_c('EnvelopeIcon',{staticClass:"w-6 h-6"}),_c('a',{staticClass:"text-brandGreen",attrs:{"href":`mailto:${_vm.partnerSSCData.email}`}},[_vm._v(_vm._s(_vm.partnerSSCData.email))])],1)],1),_c('hr',{staticClass:"mt-4 -mx-2"}),_c('table3',{attrs:{"header":{ cellA: 'Part Number', cellB: 'Need', cellC: 'Available' },"body":{
          cellA: {
            title: 'partNumber',
          },
          cellB: 'need',
          cellC: 'available',
        },"items":_vm.items,"itemHasCursor":false,"trafficLightItems":['available'],"options":{ cellARegularFont: true },"itemKey":"partNumber"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }