var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Master',[_c('div',{staticClass:"inventory-details"},[(_vm.$store.state.inventory.inventoryStockLoading)?_c('loader'):_vm._e(),(_vm.partMeta)?_c('div',{staticClass:"container"},[_c('InventoryHeader'),_c('div',{},[_c('PageHeading',{attrs:{"title":_vm.partMeta.partNumber}},[(_vm.partMeta.strategy && _vm.partMeta.strategy.includes('MTS'))?_c('a',{staticClass:"icon-swg-info text-brandLightBlue hover:text-blue-700 text-xl",attrs:{"href":`https://products.swagelok.com/p/${_vm.partMeta.partNumber}`,"target":"_blank"}}):_vm._e()]),_c('div',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.partMeta.partDescription))]),_c('div',{staticClass:"mt-2 text-strategy flex justify-between items-center"},[_c('div',{staticClass:"font-bold",attrs:{"id":"inv-fulfillment-strategy-code"}},[_vm._v(_vm._s(_vm.partMeta.strategy))]),(
              _vm.isIVAOrderingFunctionalityEnabled &&
              (_vm.isAdmin || _vm.isInOrderingGroup) &&
              _vm.IVAOrderingFunctionality &&
              _vm.isSSCVisible &&
              ((!this.isWsCorp && this.allowedGroups) ||
                this.allowedGroups) &&
              _vm.sellPrice
            )?_c('div',[_c('span',{staticClass:"mr-2"},[_vm._v("List Price")]),_c('span',{staticClass:"text-headerColor text-3xl"},[_vm._v(_vm._s(_vm.sellPrice))])]):_vm._e()])],1),_c('hr',{staticClass:"mt-3 -mx-2"}),_c('div',{staticClass:"mt-4"},[_c('PageHeading',{attrs:{"title":_vm.ssc.sscId}}),_c('div',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.ssc.sscName))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ssc.sscId !== 'SWGLK'),expression:"ssc.sscId !== 'SWGLK'"}],staticClass:"flex mt-2"},[_c('div',{staticClass:"icon-swg-phone text-brandBlue text-xl mr-2"}),(_vm.ssc.phone)?_c('a',{staticClass:"text-brandGreen",attrs:{"href":`tel:#${_vm.ssc.phone}`}},[_vm._v(_vm._s(_vm.ssc.phone))]):_c('div',[_vm._v("N/A")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ssc.sscId !== 'SWGLK'),expression:"ssc.sscId !== 'SWGLK'"}],staticClass:"flex mt-1 gap-2"},[_c('EnvelopeIcon',{staticClass:"w-6 h-6"}),(_vm.ssc.email)?_c('a',{staticClass:"text-brandGreen",attrs:{"href":`mailto:${_vm.ssc.email}?subject=Inventory Visibility inquiry for part ${_vm.partMeta.partNumber}`}},[_vm._v(_vm._s(_vm.ssc.email))]):_c('div',[_vm._v("N/A")])],1)],1),_c('hr',{staticClass:"mt-4 -mx-2"}),(_vm.items.length && !_vm.isHomeSSC && !_vm.ssc.inventoryUoMValue)?_c('table2',{attrs:{"header":{ cellA: 'Branch', cellB: 'Available' },"body":{
          cellA: { title: 'title' },
          cellB: 'available',
        },"options":{ cellARegularFont: true, truncate: true },"items":_vm.items,"itemHasCursor":false,"itemKey":"branchId","withPlusSign":true}}):_vm._e(),(_vm.items.length && !_vm.isHomeSSC && _vm.ssc.inventoryUoMValue)?_c('table3',{attrs:{"header":{ cellA: 'Branch', cellB: 'Available', cellC: '' },"body":{
          cellA: { title: 'title' },
          cellB: 'available',
          cellC: 'inventoryUoMValue',
        },"options":{
          cellARegularFont: true,
          truncate: true,
          cellCSize: 'w-[32px] !p-0 flex-none',
          cellBClass: '!w-full pr-2',
        },"items":_vm.items,"itemHasCursor":false,"itemKey":"branchId","withPlusSign":true}}):_vm._e(),(_vm.ssc.inventory.length && _vm.isHomeSSC)?_c('table3',{attrs:{"header":{ cellA: 'Branch', cellB: 'Available', cellC: 'Reserved' },"body":{
          cellA: { title: 'title' },
          cellB: 'available',
          cellC: 'reserved',
        },"options":{ cellARegularFont: true, truncate: true },"items":_vm.ssc.inventory,"itemHasCursor":false,"itemKey":"branchId"}}):_vm._e(),(!_vm.canOrder)?_c('div',{staticClass:"text-center mt-8 italic text-secondaryGrayer text-sm"},[_vm._v(" "+_vm._s(_vm.cannotOrderMessage)+" ")]):_vm._e(),(_vm.canOrder && _vm.IVAOrderingFunctionality)?_c('InventoryAddToCart',{attrs:{"IVAOrderingFunctionality":_vm.IVAOrderingFunctionality,"minQty":_vm.$route.params.minQty,"readyNow":_vm.readyNow,"availability":_vm.totalAvailable,"partNumber":_vm.partMeta.partNumber,"sellingSSC":_vm.ssc.sscId,"requestingSSC":_vm.homeSscDummy.sscId,"price":Number(_vm.inventoryPrice),"sellingSSCEmail":_vm.ssc.email,"currency":_vm.ssc.sellListPriceCurrency,"bpCode":_vm.BPCode},on:{"onQuantityChange":_vm.onQuantityChange}}):_vm._e(),(_vm.ssc.sscId === 'SWGLK')?_c('div',{staticClass:"mt-10"},[_c('div',{staticClass:"text-xl font-bold text-headerColor"},[_vm._v(" Future Corporate Stock ")]),(_vm.futureStock.length === 0)?_c('div',{staticClass:"mt-2"},[_c('hr'),_c('div',{staticClass:"mt-4 mb-4"},[_vm._v("No future stock scheduled at this time")]),_c('hr')]):_vm._e(),(_vm.ssc.inventory.length && _vm.futureStock.length)?_c('table3',{attrs:{"header":{ cellA: 'Branch', cellB: 'Quantity', cellC: 'Date' },"body":{
            cellA: { title: 'branchId' },
            cellB: 'quantity',
            cellC: 'date',
          },"options":{
            textSize: 'text-[16px]',
            cellCSize: 'w-[30%]',
          },"items":_vm.futureStock,"itemHasCursor":false,"itemKey":"branchId"}}):_vm._e()],1):_vm._e()],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }