var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"flex justify-between py-2.5"},[_c('div',{staticClass:"font-bold text-headerColor w-[44%] whitespace-nowrap"},[_vm._v(" "+_vm._s(_vm.header.cellA)+" ")]),_c('div',{staticClass:"font-bold text-headerColor w-[34%] text-right",class:[_vm.options && _vm.options.cellBClass ? _vm.options.cellBClass : '']},[_vm._v(" "+_vm._s(_vm.header.cellB)+" ")]),_c('div',{staticClass:"font-bold text-headerColor text-right",class:[_vm.options && _vm.options.cellCSize ? _vm.options.cellCSize : 'w-[25%]']},[_vm._v(" "+_vm._s(_vm.header.cellC)+" ")])]),_c('hr',{staticClass:"-mx-2"}),_c('transition-group',{attrs:{"name":"transition-list","tag":"div"}},_vm._l((_vm.items),function(item,idx){return _c('div',{key:item[_vm.itemKey] + idx,staticClass:"transition-list-item",on:{"click":function($event){return _vm.$emit('itemClicked', item)}}},[_c('div',{staticClass:"-mx-2 transition-colors hover:bg-gray-100 space-y-2 py-2"},[_c('div',{staticClass:"flex justify-between items-center px-2",class:_vm.itemHasCursor ? 'cursor-pointer' : null},[_c('div',{staticClass:"w-[44%]"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"text-headerColor mr-1",class:{
                  'font-bold': !_vm.options.cellARegularFont,
                  'brake-word': _vm.options.brakeWord,
                  truncate: !!_vm.options.truncate,
                },domProps:{"innerHTML":_vm._s(
                  _vm.options && _vm.options.lineBreak
                    ? item[_vm.body.cellA.title].replaceAll(', ', '<br />')
                    : item[_vm.body.cellA.title]
                )}}),(item[_vm.body.cellA.hasIcon])?_vm._t("cellAIcon"):_vm._e()],2),_c('div',{staticClass:"truncate text-headerColor"},[_vm._v(" "+_vm._s(item[_vm.body.cellA.subtitle])+" ")])]),_c('div',{staticClass:"text-headerColor w-[34%] text-right",class:[
              _vm.options && _vm.options.textSize ? _vm.options.textSize : 'text-[22px]',
              _vm.options && _vm.options.cellBClass ? _vm.options.cellBClass : '',
            ]},[_vm._v(" "+_vm._s(item[_vm.body.cellB])+" ")]),_c('div',{staticClass:"text-headerColor text-right pl-[1.3rem]",class:[
              _vm.options && _vm.options.cellCSize ? _vm.options.cellCSize : 'w-[25%]',
              _vm.options && _vm.options.textSize ? _vm.options.textSize : 'text-[22px]',
              _vm.options && _vm.options.trafficLights === 'displayComponents'
                ? 'flex justify-between items-center'
                : _vm.options && _vm.options.trafficLights === 'color'
                ? 'flex justify-center items-center'
                : null,
            ],domProps:{"innerHTML":_vm._s(
              _vm.options && _vm.options.trafficLights === 'displayComponents'
                ? (item['displayComponents'].every((el) => el.available > 0)
                    ? _vm.green
                    : item['displayComponents'].some((el) => el.available > 0)
                    ? _vm.yellow
                    : _vm.red) + item[_vm.body.cellC]
                : _vm.options && _vm.options.trafficLights === 'color'
                ? (item[_vm.body.cellB] === 'green'
                    ? _vm.green
                    : item[_vm.body.cellB] === 'yellow'
                    ? _vm.yellow
                    : _vm.red) + item[_vm.body.cellC]
                : item[_vm.body.cellC]
            )}}),(_vm.showUOM)?_c('div',{staticClass:"absolute right-[-28px]"},[_c('b',{staticClass:"text-black"},[_vm._v(_vm._s(item.inventoryUoMValue))])]):_vm._e()]),(_vm.footer && item[_vm.footer] && item[_vm.footer].length)?_c('div',{staticClass:"px-2 font-medium text-sm"},[_c('span',[_vm._v(_vm._s(item[_vm.footer]))])]):_vm._e()]),_c('hr',{staticClass:"-mx-2"})])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }