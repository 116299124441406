var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"inv-search-inventory-results","data-inv-met-criteria-home":!!(_vm.sscData && _vm.sscData.length && _vm.sscData[0].totalPartsFound) && '',"data-inv-met-criteria-peer":!!(_vm.sscData && _vm.sscData.length > 1 && _vm.sscData[1].totalPartsFound) && '',"data-inv-searched-full-network":_vm.isSearchComplete && !_vm.isLoading && ''}},[(_vm.sscData && !_vm.isLoading)?_c('div',[(_vm.partMeta)?[_c('hr',{staticClass:"-mx-2"}),_c('div',{staticClass:"mt-3"},[_c('PageHeading',{attrs:{"title":_vm.partMeta.partNumber}}),_c('div',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.partMeta.partDescription))]),_c('div',{staticClass:"flex justify-between mt-2 mb-4"},[_c('div',{staticClass:"text-strategy font-bold",attrs:{"id":"inv-fulfillment-strategy-code"}},[_vm._v(" "+_vm._s(_vm.partMeta.strategy)+" ")]),_c('SvgQuestionCircle',{staticClass:"text-brandLightBlue hover:text-blue-700 w-5 h-5 fill-current cursor-pointer",on:{"click":_vm.showHelpReserve}})],1),(_vm.isIVAOrderingFunctionalityEnabled)?_c('div',{staticClass:"italic text-brandLightBlue text-sm mb-2"},[_vm._v(" Select a location to order product ")]):_vm._e(),_c('div',{staticClass:"italic text-brandLightBlue text-sm"},[_vm._v(" Reserved qty indicated by a plus (+) sign ")])],1)]:_vm._e(),(_vm.sscData.length)?[_c('hr',{staticClass:"-mx-2 mt-2"}),(_vm.showUOM)?_c('table3',{attrs:{"header":{
          cellA: 'Location',
          cellB: 'Available',
          cellC: '',
        },"body":{
          cellA: {
            title: 'sscId',
            subtitle: 'sscName',
            hasIcon: 'isHome',
          },
          cellB: 'totalAvailable',
          cellC: 'inventoryUoMValue',
        },"options":{
          cellCSize: 'w-[32px] !p-0 flex-none',
          cellBClass: '!w-full pr-2',
        },"items":_vm.items,"itemHasCursor":true,"itemKey":"sscId"},on:{"itemClicked":(item) => _vm.showDetails(item)},scopedSlots:_vm._u([{key:"cellAIcon",fn:function(){return [_c('div',{staticClass:"icon-swg-home text-brandYellow text-xl leading-none top-0.5 relative"})]},proxy:true}],null,false,3958391559)}):_c('Table2',{attrs:{"header":{
          cellA: 'Location',
          cellB: 'Available',
          cellC: 'Reserved',
        },"body":{
          cellA: {
            title: 'sscId',
            subtitle: 'sscName',
            hasIcon: 'isHome',
          },
          cellB: 'totalAvailable',
        },"options":{
          showReadyNow: this.isIVAOrderingFunctionalityEnabled,
        },"withPlusSign":true,"items":_vm.items,"itemHasCursor":true,"itemKey":"sscId"},on:{"itemClicked":(item) => _vm.showDetails(item)},scopedSlots:_vm._u([{key:"cellAIcon",fn:function(){return [_c('div',{staticClass:"icon-swg-home text-brandYellow text-xl leading-none top-0.5 relative"})]},proxy:true}],null,false,3958391559)})]:_vm._e(),(_vm.showNoResult && _vm.isSearchComplete)?_c('div',{},[_c('hr',{staticClass:"-mx-2 mt-4"}),_c('div',{staticClass:"mt-4"},[_vm._v("Part not found.")]),_c('hr',{staticClass:"-mx-2 mt-4"})]):_vm._e(),(!_vm.isSearchComplete && !_vm.isLoading)?_c('div',{staticClass:"link-a mt-4 hover:text-brandGreen-darker font-semibold",attrs:{"id":"inv-search-more-sscs"},on:{"click":function($event){return _vm.$emit('searchMoreClicked')}}},[_vm._v(" "+_vm._s('Search More Locations')+" "),_c('span',{staticClass:"icon-swg-arrow-right"})]):(_vm.isSearchComplete && !_vm.isLoading)?_c('div',{staticClass:"flex items-center mt-4"},[_c('SvgExclamationTriangle',{staticClass:"text-brandYellow w-[22px] h-[22px] fill-current"}),_c('div',{staticClass:"flex justify-center items-center ml-4"},[_vm._v(" The full network has been searched. ")])],1):_vm._e()],2):_vm._e(),(_vm.isLoading)?_c('loader'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }