<template>
  <div
    v-if="(isInOrderingGroup && isIVAOrderingFunctionalityEnabled) || isAdmin"
  >
    <loader v-if="isLoading" />

    <div class="justify-between items-center mt-5" v-if="!readyNow">
      <div class="mb-3 text-headerColor font-bold">Optional</div>
      <div class="text-sm text-headerColor mb-4">
        Add additional information for your request below.
      </div>

      <textarea
        class="
          form-textarea
          placeholder-italic
          h-[100px]
          rounded-none
          resize-none
        "
        v-model="requestMessage"
        type="text"
        placeholder="I need to..."
      />
    </div>

    <div class="flex justify-between mt-8 gap-6 items-center">
      <div class="flex items-center gap-2">
        <button
          @click="decrement"
          class="text-[20px]"
          :class="[!canDecrement ? 'text-[#c3c7c9]' : 'text-strategy']"
        >
          -
        </button>
        <div
          class="
            border
            rounded-[4px]
            border-[#c5c7c8]
            max-w-[130px]
            w-[68px]
            h-[50px]
            flex-0
            overflow-hidden
          "
        >
          <input
            type="number"
            @keyup="
              (e) => {
                if (parseInt(e.target.value) > availability) {
                  quantity = availability
                }
              }
            "
            v-model="quantity"
            class="block text-center text-[20px] w-full h-full"
            id="inv-requested-quantity"
          />
        </div>
        <button
          @click="increment"
          class="text-[20px]"
          :class="[!canIncrement ? 'text-[#c3c7c9]' : 'text-strategy']"
        >
          +
        </button>
      </div>
      <div class="w-full max-w-[200px]">
        <button
          v-if="!readyNow || (readyNow && !price)"
          @click="
            () => {
              submitRequest({ quantity, partNumber })
              requestBottomSheet = true
            }
          "
          type="button"
          :disabled="!canAddToCart"
          class="button-a w-full block"
          id="inv-submit-request"
        >
          SUBMIT A REQUEST
        </button>

        <button
          v-if="readyNow && price"
          @click="
            () => {
              tryAddToCart()
            }
          "
          type="button"
          :disabled="
            !canAddToCart || !price || IVAOrderingFunctionality === false
          "
          class="button-a w-full block"
          id="inv-add-to-cart"
        >
          ADD TO CART
        </button>
      </div>

      <swipe-modal v-model="cardBottomSheet">
        <div>
          <div class="flex justify-end px-2">
            <InputClearButton
              @click="
                () => {
                  cardBottomSheet = false
                }
              "
            />
          </div>
          <div class="flex justify-center mb-2">
            <CartIcon class="w-[24px] h-[24px]" />
          </div>
          <div
            class="text-center text-xl text-headerColor font-semibold px-2 mb-6"
          >
            {{ quantity }} product(s) were added to your cart
          </div>
          <div class="bg-[#f0f0f0] py-6 px-4">
            <button
              @click="openCart"
              type="button"
              class="button-a w-full block mb-4"
            >
              VIEW CART
            </button>
            <button
              @click="
                () => {
                  cardBottomSheet = false
                }
              "
              type="button"
              class="button-b !bg-white w-full block"
            >
              Continue shopping
            </button>
          </div>
        </div>
      </swipe-modal>

      <swipe-modal v-model="requestBottomSheet">
        <div>
          <div class="flex justify-end px-2 pb-6">
            <InputClearButton
              @click="
                () => {
                  requestBottomSheet = false
                }
              "
            />
          </div>
          <div
            class="text-center text-xl text-headerColor font-semibold px-2 mb-4"
          >
            Request Submitted
          </div>
          <div class="flex justify-center mb-12">
            <button
              @click="
                () => {
                  setPath({
                    name: 'OrderRequestsNotifications',
                    routeMap: {
                      goBackState: {
                        name: 'Inventory',
                      },
                    },
                  })
                }
              "
              type="button"
              class="link-main-menu underline"
            >
              View Submitted Requests
            </button>
          </div>
        </div>
      </swipe-modal>
    </div>

    <div
      v-if="showPriceMessage && IVAOrderingFunctionality === false"
      class="mt-4"
    >
      {{ sellingSSC }} has not opted-in to show prices/sell via IVA, therefore
      you are unable to place this order electronically.
    </div>

    <div
      v-if="showPriceMessage && IVAOrderingFunctionality === true && !price"
      class="mt-4"
    >
      {{ sellingSSC }} has not configured B1 to price this product for you,
      therefore you are unable to place this order electronically.
    </div>

    <div class="text-red-500 mt-4" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>
import CartIcon from '@/assets/icons/cart.svg'
import { debounce } from '@/helpers/utils'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import PageMixin from '@/mixins/PageMixin'
import SwagelokApiService from '@/services/swagelok-api'
import settings from '@/settings'
import swipeModal from '@takuma-ru/vue-swipe-modal'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'InventoryAddToCart',
  mixins: [PageMixin, AuthorizedGuardMixin],
  methods: {
    ...mapActions('cart', ['openCart', 'addToCart']),
    ...mapActions('settings', ['sendEmail']),
    async tryAddToCart() {
      this.errorMessage = ''
      this.isLoading = true

      try {
        await this.addToCart({
          quantity: this.quantity,
          partNumber: this.partNumber,
          sellingSSC: this.sellingSSC,
          buyingSSC: this.requestingSSC,
          maxQuantity: this.availability,
          price: this.price,
          bpCode: this.bpCode
            ? this.bpCode === 'eCommBP'
              ? 'IVABP'
              : this.bpCode
            : 'IVABP',
          currency: this.currency,
        })
        this.$emit('onAddToCart')
        this.cardBottomSheet = true
      } catch (e) {
        this.errorMessage = 'Cannot add to cart. Please try again later'
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    async submitRequest() {
      let message = this.requestMessage

      const formattedPrice = new Intl.NumberFormat('default', {
        style: 'currency',
        currency: this.currency,
      }).format(this.price)

      if (this.price) {
        message += `
          \nThe price for this customer is: ${formattedPrice}. 
          \nThe BPCode associated with this customer is: ${this.bpCode}.
        `;
      } else {
        message += this.IVAOrderingFunctionality
          ? `\nThis part number does not have a price associated with it for this SSC. To enable this SSC to purchase this part from you in IVA, you must set up this customer to have a price for this part. The BPCode for this customer is: ${this.bpCode}`
          : `\nYour SSC has not opted-in to display pricing to other SSCs. ​In order for other SSCs to purchase from you through IVA, your SSC must opt-in to display pricing to other SSCs. Your distributor principal should have received an email about opting-in in August 2023.`
      }

      await SwagelokApiService.inventory.submitRequest({
        partNumber: this.partNumber,
        requestedQuantity: this.quantity,
        requestMessage: message ?? '',
        sellingBranch: '',
        sellingSSC: this.sellingSSC,
        requestingSSC: this.requestingSSC,
      })

      const { uid: loggedUserEmail, fullName, ssc } = this.userData
      const userName =
        fullName.indexOf(',') === -1
          ? fullName
          : fullName.split(',')[1] + ' ' + fullName.split(',')[0]

      const html = `
            <div>
              <p style="margin-bottom: 8px;">An SSC is requesting to order parts from your SSC.</p>

              <p style="margin-bottom: 8px;">
                <strong>Request Information</strong>
              </p>

              <p>Part Number: ${this.partNumber}</p>
              <p style="margin-bottom: 8px;">Requested Qty: ${this.quantity}</p>

              <p>Submitted By (SSC): ${ssc.name} (${this.requestingSSC})</p>
              <p>Submitted By (User): ${userName}</p>
              <p style="margin-bottom: 8px;">User Email: ${loggedUserEmail.toLocaleLowerCase()}</p>

              <p style="margin-bottom: 8px;">Notes: ${message}</p>

              <p style="margin-bottom: 0;">
                <a style="color: dodgerblue;text-decoration: underline;" href="${
                  settings.app.baseURL
                }/order-requests">Approve or Deny Request in IVA</a>
              </p>
            </div>
          `

      const fromName = this.appSettings.EmailFromLoggedInUser
        ? fullName
        : 'Swagelok Webmaster'
      const fromEmail = this.appSettings.EmailFromLoggedInUser
        ? loggedUserEmail
        : 'webmaster@swagelok.com'

      await this.sendEmail({
        fromName,
        fromEmail,
        to: this.sellingSSCEmail,
        replyTo: loggedUserEmail,
        cc: loggedUserEmail,
        subject: `${
          process.env.VUE_APP_ENV === 'prod' ? '' : 'TEST: '
        } New Request for ${this.partNumber}`,
        text: this.requestMessage,
        html,
      })

      this.requestBottomSheet = true
    },
    increment() {
      if (!this.canIncrement) return
      this.quantity = this.quantity + 1
    },
    decrement() {
      if (!this.canDecrement) return
      this.quantity = this.quantity - 1
    },
  },
  components: {
    swipeModal,
    CartIcon,
  },
  computed: {
    ...mapGetters('user', ['isInOrderingGroup', 'isAdmin']),
    ...mapGetters('settings', ['isIVAOrderingFunctionalityEnabled']),
    ...mapState('settings', ['appSettings']),
    canAddToCart() {
      return (
        this.quantity > 0 && !this.$store.state.inventory.inventoryStockLoading
      )
    },
    canIncrement() {
      return this.quantity < this.availability
    },
    canDecrement() {
      return this.quantity > 0
    },
  },

  created() {
    this.debouncedQuantityUpdate = debounce((newValue) => {
      this.$emit('onQuantityChange', newValue)
    }, 500)

    if (this.minQty) {
      this.quantity = parseInt(this.minQty)
    }

    if (this.initialQty) {
      this.quantity = parseInt(this.initialQty)
    }
  },
  watch: {
    quantity(newValue, oldValue) {
      if (oldValue === 0 || newValue === 0 || newValue === oldValue) {
        return
      }

      this.debouncedQuantityUpdate(newValue)
    },
  },
  props: {
    readyNow: {
      type: Boolean,
      required: true,
    },
    sellingSSC: {
      type: String,
      required: true,
    },
    requestingSSC: {
      type: String,
      required: true,
    },
    partNumber: {
      type: String,
      required: true,
    },
    availability: {
      type: Number,
      required: true,
    },
    minQty: {
      required: false,
    },
    initialQty: {
      type: Number,
      required: false,
    },
    onAddToCart: {
      type: Function,
      required: false,
    },
    price: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    bpCode: {
      type: String,
    },
    sellingSSCEmail: {
      type: String,
    },
    IVAOrderingFunctionality: {
      type: Boolean,
    },
    showPriceMessage: {
      type: Boolean,
    },
  },
  data() {
    return {
      requestMessage: '',
      quantity: 0,
      cardBottomSheet: false,
      requestBottomSheet: false,
      isLoading: false,
      errorMessage: '',
    }
  },
}
</script>
