var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"flex justify-between py-2.5"},[_c('div',{staticClass:"font-bold text-headerColor w-[60%]",class:_vm.helpPage && 'flex items-center'},[_vm._v(" "+_vm._s(_vm.header.cellA)+" "),(_vm.helpPage)?_c('button',{staticClass:"ml-2 w-[20px]",on:{"click":_vm.handleHelpClick}},[_c('QuestionCircleSolid',{staticClass:"text-brandLightBlue"})],1):_vm._e()]),_c('div',{staticClass:"font-bold text-headerColor w-[40%] text-right"},[_vm._v(" "+_vm._s(_vm.header.cellB)+" ")])]),_c('hr',{staticClass:"-mx-2"}),_c('transition-group',{attrs:{"name":"transition-list","tag":"div"}},_vm._l((_vm.items),function(item,idx){return _c('div',{key:item[_vm.itemKey] + idx,staticClass:"transition-list-item",on:{"click":function($event){return _vm.$emit('itemClicked', item)}}},[_c('div',{staticClass:"-mx-2 transition-colors hover:bg-gray-100"},[_c('div',{staticClass:"flex justify-between items-center py-2 px-2",class:_vm.itemHasCursor ? 'cursor-pointer' : null},[_c('div',{staticClass:"w-[60%]"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"text-headerColor mr-1",class:{
                  'font-bold': !_vm.options.cellARegularFont,
                  truncate: _vm.options.truncate,
                  'whitespace-pre-line': !_vm.options.truncate,
                }},[_vm._v(" "+_vm._s(item[_vm.body.cellA.title])+" ")]),(item[_vm.body.cellA.hasIcon])?_vm._t("cellAIcon"):_vm._e()],2),_c('div',{staticClass:"truncate text-headerColor"},[_vm._v(" "+_vm._s(item[_vm.body.cellA.subtitle])+" ")])]),(_vm.trafficLightItems && _vm.hideValue)?_c('div',{staticClass:"text-[22px] text-headerColor w-[40%] text-right"},[_c('div',{staticClass:"flex",class:_vm.trafficLightItems.includes(_vm.body.cellB) &&
                !_vm.hideValue.includes(_vm.body.cellB)
                  ? 'justify-between items-center'
                  : 'justify-end items-center'},[(_vm.trafficLightItems.includes(_vm.body.cellB))?_c('TrafficLight',{class:_vm.trafficLightItems.includes(_vm.body.cellB) &&
                  !_vm.hideValue.includes(_vm.body.cellB)
                    ? 'ml-4'
                    : '',attrs:{"color":item['trafficLight']}}):_vm._e(),(!_vm.hideValue.includes(_vm.body.cellB))?_c('div',[_vm._v(" "+_vm._s(item[_vm.body.cellB])+" ")]):_vm._e()],1)]):_c('div',{staticClass:"text-[22px] text-headerColor w-[30%] text-right"},[_vm._v(" "+_vm._s(item[_vm.body.cellB])+" ")])])]),_c('hr',{staticClass:"-mx-2"})])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }