import SwagelokApiService from '@/services/swagelok-api'

const state = {
  SSC2SSCPoliciesReadDateData: null,
  appSettings: null,
  userSSCSettings: {},
}

const mutations = {
  SET_SSC2SSC_SOURCING_POLICIES_DATA(state, data) {
    state.SSC2SSCPoliciesReadDateData = data
  },
  SET_APP_SETTINGS(state, data) {
    state.appSettings = data
  },
  SET_USER_SSC_SETTINGS(state, data) {
    state.userSSCSettings = data
  },
}

const actions = {
  async searchSSCs({ commit }, sscValue) {
    return await SwagelokApiService.userSettings.searchSSCs(sscValue)
  },
  async getFavoriteSSCsByUser({ commit }) {
    return await SwagelokApiService.userSettings.getFavoriteSSCsByUser()
  },
  async handleUpdateFavoriteList({ commit }, updatedFavoriteList) {
    return await SwagelokApiService.userSettings.handleUpdateFavoriteList(
      updatedFavoriteList,
    )
  },
  async getBatchSize() {
    return await SwagelokApiService.userSettings.getBatchSize()
  },
  async handleUpdateBatchSize({ commit }, batchSize) {
    return await SwagelokApiService.userSettings.handleUpdateBatchSize(
      batchSize,
    )
  },
  async getSwagelokBranch() {
    return await SwagelokApiService.userSettings.getSwagelokBranch()
  },
  async setSwagelokBranch({ commit }, swagelokBranch) {
    if (swagelokBranch === '') {
      swagelokBranch = 'all'
    }

    return await SwagelokApiService.userSettings.handleSwagelokBranch(
      swagelokBranch,
    )
  },
  async getSSCSettings({ commit }, whoToCode) {
    return await SwagelokApiService.SSCSettings.get(whoToCode)
  },
  async getUserSSCSettings({ commit }, whoToCode) {
    try {
      const settings = await SwagelokApiService.SSCSettings.get(whoToCode)
      if (!settings || !settings.resource) {
        return
      }

      commit('SET_USER_SSC_SETTINGS', settings.resource)
    } catch (e) {
      console.log(e)
    }
  },
  async setSSC2SSCPoliciesReadDate({ commit }) {
    return await SwagelokApiService.userSettings.setSSC2SSCPoliciesReadDate()
  },
  async getSSC2SSCPoliciesReadDate({ commit }) {
    const SSC2SSCPoliciesReadDate =
      await SwagelokApiService.userSettings.getSSC2SSCPoliciesReadDate()

    commit('SET_SSC2SSC_SOURCING_POLICIES_DATA', SSC2SSCPoliciesReadDate)
  },

  async sendEmail(
    _,
    { fromName, fromEmail, to, replyTo, cc, subject, text, html },
  ) {
    return await SwagelokApiService.userSettings.sendEmail({
      fromName,
      fromEmail,
      to,
      replyTo,
      cc,
      subject,
      text,
      html,
    })
  },

  async updateSingleSetting(_, { name, value, updateMethod, options }) {
    return await SwagelokApiService.userSettings.updateSingleSetting(
      name,
      value,
      updateMethod,
      options,
    )
  },
  async updateSSCSettings(_, { id, operations }) {
    return await SwagelokApiService.userSettings.updateSSCSettings({
      id,
      operations,
    })
  },
  async getUserSettings() {
    return await SwagelokApiService.userSettings.getUserSettings()
  },
  async getAppSettings({ commit }) {
    const settings = await SwagelokApiService.GET('/settings/app')
    commit('SET_APP_SETTINGS', Array.isArray(settings) ? settings[0] : settings)
  },
}

const getters = {
  getSSC2SSCPoliciesReadDateData: (state) => {
    return state.SSC2SSCPoliciesReadDateData
  },
  isIVAOrderingFunctionalityEnabled() {
    return state?.userSSCSettings?.IVAOrderingFunctionality
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true,
}
