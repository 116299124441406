var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"flex justify-between py-2.5"},[_c('div',{staticClass:"font-bold text-headerColor w-[70%] flex"},[_vm._v(" "+_vm._s(_vm.header.cellA)+" "),(_vm.helpPage && !_vm.helpPageOnRight)?_c('button',{staticClass:"ml-2 w-[20px]",on:{"click":_vm.handleHelpClick}},[_c('QuestionCircleSolid',{staticClass:"text-brandLightBlue"})],1):_vm._e()]),_c('div',{class:[
        'font-bold text-headerColor w-[30%] text-right flex items-center justify-end',
        _vm.withPlusSign ? 'pr-5' : '',
      ]},[_vm._v(" "+_vm._s(_vm.header.cellB)+" "),(_vm.helpPage && _vm.helpPageOnRight)?_c('button',{staticClass:"ml-2 w-[20px]",on:{"click":_vm.handleHelpClick}},[_c('QuestionCircleSolid',{staticClass:"text-brandLightBlue"})],1):_vm._e()])]),_c('hr',{staticClass:"-mx-2"}),_c('transition-group',{attrs:{"name":"transition-list","tag":"div"}},_vm._l((_vm.orderedItems),function(item,idx){return _c('div',{key:item[_vm.itemKey] + idx,staticClass:"transition-list-item",on:{"click":function($event){return _vm.$emit('itemClicked', item)}}},[_c('div',{staticClass:"-mx-2 transition-colors hover:bg-gray-100 py-2"},[_c('div',{staticClass:"flex justify-between items-center py-2 px-2",class:[
            _vm.itemHasCursor ? 'cursor-pointer' : null,
            item.isHome && _vm.options.homeNotClickable ? 'isHome' : null,
          ]},[_c('div',{staticClass:"min-w-0"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"text-headerColor mr-1 min-w-0",class:{
                  'font-bold': !_vm.options.cellARegularFont,
                  truncate: _vm.options.truncate,
                  'whitespace-pre-line': !_vm.options.truncate,
                  'brake-word': _vm.options.brakeWord,
                }},[_vm._v(" "+_vm._s(item[_vm.body.cellA.title])+" ")]),(item[_vm.body.cellA.hasIcon])?_vm._t("cellAIcon"):_vm._e()],2),_c('div',{staticClass:"truncate text-headerColor"},[_vm._v(" "+_vm._s(item[_vm.body.cellA.subtitle])+" ")])]),(_vm.withPlusSign)?_c('div',{staticClass:"text-[22px] text-headerColor flex justify-between"},[_c('span',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item[_vm.body.cellB].includes('+') ? item[_vm.body.cellB].slice(0, -1) : item[_vm.body.cellB])+" ")]),_c('span',{staticClass:"w-[13px]"},[_vm._v(" "+_vm._s(item[_vm.body.cellB].includes('+') ? '+' : '')+" ")])]):_vm._e(),(!_vm.withPlusSign)?_c('div',{staticClass:"text-[22px] text-headerColor text-right",class:[
              _vm.options && _vm.options.trafficLights === 'displayComponents'
                ? 'flex justify-between items-center'
                : _vm.options && _vm.options.trafficLights === 'color'
                ? 'flex justify-end items-end mr-5'
                : null,
              _vm.helpPageOnRight ? 'pr-3' : null,
              item[_vm.body.cellB] && item[_vm.body.cellB].includes
                ? !item[_vm.body.cellB].includes('+') && 'col-with-plus'
                : '',
            ],domProps:{"innerHTML":_vm._s(
              _vm.options && _vm.options.trafficLights === 'displayComponents'
                ? (item['displayComponents'].every((el) => el.available > 0)
                    ? _vm.green
                    : item['displayComponents'].some((el) => el.available > 0)
                    ? _vm.yellow
                    : _vm.red) + item[_vm.body.cellB]
                : _vm.options && _vm.options.trafficLights === 'color'
                ? item[_vm.body.cellB] === 'green'
                  ? _vm.green
                  : item[_vm.body.cellB] === 'yellow'
                  ? _vm.yellow
                  : item[_vm.body.cellB] === 'red'
                  ? _vm.red
                  : _vm.unavailable
                : item[_vm.body.cellB]
            )}}):_vm._e()]),(_vm.footer && item[_vm.footer] && item[_vm.footer].length)?_c('div',{staticClass:"px-2 font-medium text-sm"},[_c('span',[_vm._v(_vm._s(item[_vm.footer]))])]):_vm._e(),(_vm.options.showReadyNow && !item.isHome && item.readyNow)?_c('div',{staticClass:"font-bold text-brandLightBlue text-xs flex gap-1 justify-end"},[_c('Star',{staticClass:"w-3"}),_vm._v(" Ready now ")],1):_vm._e()]),_c('hr',{staticClass:"-mx-2"})])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }