var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Master',[(_vm.$store.state.inventory.inventoryStockLoading)?_c('loader'):_vm._e(),(!_vm.$store.state.inventory.inventoryStockLoading && _vm.item)?_c('div',{staticClass:"container"},[_c('OrderHeader',[_c('ArrowRight',{staticClass:"w-5"}),_vm._v(" Incoming Request ")],1),_c('Order',{attrs:{"order":_vm.item,"whoTo":_vm.item.RequestingSSC}},[(_vm.item.Status === 'Requested')?_c('div',[(_vm.item.RequestMessage)?_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"text-xl font-bold text-headerColor mb-1"},[_vm._v("Message")]),_c('div',{staticClass:"text-sm text-headerColor whitespace-pre-line",domProps:{"innerHTML":_vm._s(_vm.item.RequestMessage)}})]):_vm._e(),_c('div',{staticClass:"text-xl font-bold text-headerColor mb-1"},[_vm._v("Local Stock")]),_c('hr'),_c('table3',{attrs:{"header":{
            cellA: 'Branch',
            cellB: 'Available',
            cellC: 'Reserved',
          },"body":{
            cellA: { title: 'branch' },
            cellB: 'available',
            cellC: 'reserved',
          },"options":{
            cellARegularFont: true,
            textSize: 'text-[16px]',
            // cellCSize: 'w-[30%]',
          },"items":_vm.localStock,"itemHasCursor":false,"itemKey":"swagelokBranch"}}),_c('div',{staticClass:"text-sm font-bold text-headerColor mt-2 mb-2"},[_vm._v(" Optional ")]),_c('div',{staticClass:"text-sm text-headerColor mb-4"},[_vm._v(" Add additional information for your request below. ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"form-textarea h-[100px] rounded-none resize-none mb-3",attrs:{"type":"text","placeholder":"I'm approving/denying because..."},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing)return;_vm.message=$event.target.value}}}),_c('div',{staticClass:"text-sm text-headerColor mb-4"},[_vm._v(" How many are you approving? ")]),_c('div',{staticClass:"mb-5"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.approvedQuantity),expression:"approvedQuantity"}],staticClass:"form-input w-[80px]",attrs:{"type":"number","id":"inv-approved-quantity"},domProps:{"value":(_vm.approvedQuantity)},on:{"input":function($event){if($event.target.composing)return;_vm.approvedQuantity=$event.target.value}}})]),_c('div',{staticClass:"flex gap-2 mb-4"},[_c('button',{staticClass:"button-a",attrs:{"id":"inv-approve-request"},on:{"click":() => {
                _vm.update('Approved')
              }}},[_vm._v(" APPROVE REQUEST ")]),_c('button',{staticClass:"button-b",attrs:{"id":"inv-deny-request"},on:{"click":() => {
                _vm.update('Denied')
              }}},[_vm._v(" DENY REQUEST ")])])],1):_vm._e()]),(_vm.item.Status === 'Requested')?_c('div',[_c('div',{staticClass:"text-xl font-bold text-headerColor mb-1"},[_vm._v(" Corporate Stock ")]),_c('hr'),_c('table2',{attrs:{"header":{ cellA: 'Branch', cellB: 'Available' },"body":{
          cellA: { title: 'location' },
          cellB: 'available',
        },"options":{ cellARegularFont: true, truncate: true },"items":_vm.stock ? _vm.stock.corporateStock : [],"itemHasCursor":false,"itemKey":"location"}}),_c('div',{staticClass:"text-xl font-bold text-headerColor mt-6 mb-1"},[_vm._v(" Future Corporate Stock ")]),(_vm.stock && _vm.stock.futureCorporateStock.length === 0)?_c('div',[_c('hr'),_c('div',{staticClass:"mt-4 mb-4"},[_vm._v("No future stock scheduled at this time")]),_c('hr')]):_vm._e(),(_vm.stock && _vm.stock.futureCorporateStock.length)?_c('table3',{attrs:{"header":{ cellA: 'Branch', cellB: 'Quantity', cellC: 'Date' },"body":{
          cellA: { title: 'branchId' },
          cellB: 'quantity',
          cellC: 'date',
        },"options":{
          textSize: 'text-[16px]',
          cellCSize: 'w-[30%]',
        },"items":_vm.stock ? _vm.stock.futureCorporateStock : [],"itemHasCursor":false,"itemKey":"branchId"}}):_vm._e()],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }